<template>
  <div>
    <!-- <Header /> -->
    <layout-full>
      <router-view />
    </layout-full>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'
// import Header from '../../views/partials/Header.vue'
import Footer from '../../views/partials/Footer.vue'

export default {
  components: {
    LayoutFull,
    // Header,
    Footer,
  },
}
</script>
